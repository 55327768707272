// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import Chart from 'chart.js/auto';

Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.Chart = Chart;
window.jQuery = require('jquery');
window.$ = window.jQuery;

require("bootstrap/dist/css/bootstrap");
require('../src/modal');
require('../src/scripts');
require('../src/sidebar');
require('../src/slimscroll');
require('../src/sticky-kit');
require('../src/text-field');
require('../src/validate');

import "bootstrap/dist/js/bootstrap";
import "../stylesheets/main.scss";

document.addEventListener('DOMContentLoaded', () => {
	modal();
});

window.csrfToken = () => {
	return $('meta[name="csrf-token"]').attr('content');
}
