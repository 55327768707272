// only number

window.formatInputNumber = (el) => {
  el.addEventListener('keyup', () => {
    el.value = el.value.replace(/[^0-9]/g,'');
  })
}

window.inputNumber = () => {
  const num = document.querySelectorAll('.c-number');
  if (num) {
    Array.prototype.forEach.call(num, (el, i) => {
      formatInputNumber(el);
    })
  }
};
window.addEventListener("DOMContentLoaded", inputNumber);

window.formatInputCurrency = (el) => {
  var val = parseInt(el.value);
      if(!isNaN(val)){
        var val = formatCurrency(val);
        el.value = val;
      }
      
      el.addEventListener('keyup', () => {
        el.value = el.value.replace(/[^0-9]/g,'');

        // format input to currency
        var val = parseInt(el.value);
        if(!isNaN(val)){
          var val = formatCurrency(val);
          el.value = val;
        }
      });
}

window.inputCurrency = () => {
  var inputNumber = document.getElementsByClassName("c-only-currency");
  if (inputNumber) {
    Array.prototype.forEach.call(inputNumber, (el, i) => {
      formatInputCurrency(el);
    });
  }
}
window.addEventListener("DOMContentLoaded", inputCurrency);

window.formatCurrency = (num) => {
  var p = num.toFixed(0).split(".");
  return p[0].split("").reverse().reduce(function(acc, num, i, orig) {
      return  num=="-" ? acc : num + (i && !(i % 3) ? "." : "") + acc;
  }, "");
}

window.formatNumber = (str) => {
  return str.replace(/[^0-9]/g,'');
}


window.appendOptions = (selectElem, data) => {
  selectElem.options.length = 1;

  Array.prototype.forEach.call(data, (item, key) => {
    var opt = document.createElement('option');
    opt.appendChild( document.createTextNode(item.name) );
    opt.value = item.id;
    selectElem.appendChild(opt);
  })
}