window.modalAjax = (url, options = {}) => {
  const xml = new XMLHttpRequest(),
    modal = document.getElementById('modal');

  xml.onload = () => {
    if (xml.status >= 200 && xml.status < 400 ) {
      modal.innerHTML = xml.response;
      $('.modal').modal();
    }
  }

  xml.open(options.method || 'GET', url, true);
  xml.send();
}

window.modalTriggerOpen = (event) => {
  const url = event.target.dataset.url;
  if (url) {
    const modal = modalAjax(url);
  }
}

window.modal = () => {
  const el = document.querySelectorAll('[data-modal=true]');

  Array.prototype.forEach.call(el, (element, key) => {
    element.addEventListener('click', modalTriggerOpen);
  })
};

window.openModal = (element) => {
  // console.log('sdafas');
  const url = element.dataset.url;
  const modal = modalAjax(url);
}
